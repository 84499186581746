import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import EmptyLayout from 'src/layouts/EmptyLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import MainLayout from 'src/layouts/MainLayout';
import TabletLayout from 'src/layouts/TabletLayout';
import OperLayout from 'src/layouts/OperatorLayout';
import HomeView from 'src/views/home/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import { User } from 'react-feather';

import useAuth from 'src/hooks/useAuth';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;
        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    path: '/forbidden',
    component: lazy(() => import('src/views/errors/ForbiddenView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/release',
    component: lazy(() =>
      import('src/views/release')
    )
  },
  {
    path: '/app/plants',
    guard: AuthGuard,
    layout: EmptyLayout,
    component: lazy(() => import('src/views/account/AccountView/ChoicePlantLogin/index'))
  },
  {
    path: '/app/operator',
    guard: AuthGuard,
    layout: OperLayout,
    routes: [
      {
        exact: true,
        path: '/app/operator/homeOperator',
        component: lazy(() =>
          import('src/views/execution/homeOperator/MachinesView')
        )
      },
     
      //opening tag-ewo from tablet

      {
        exact: true,
        path: '/app/operator/homeOperator/viewEwo',
        component: lazy(() =>
          import('src/views/execution/homeOperator/assignEwoTag/Ewo')
        )
      },
      {
        exact: true,
        path: '/app/operator/homeOperator/viewTag',
        component: lazy(() =>
          import('src/views/execution/homeOperator/assignEwoTag/Tag')
        )
      },
      {
        exact: true,
        path: '/app/operator/homeOperator/newEwo',
        component: lazy(() =>
          import('src/views/planning/newEwo/equipmSelection')
        )
      },
      {
        exact: true,
        path: '/app/operator/homeOperator/ewo/create/:id?',
        component: lazy(() => import('src/views/planning/newEwo/index'))
      },

      {
        exact: true,
        path: '/app/operator/homeOperator/newTag',
        component: lazy(() =>
          import('src/views/planning/newTag/equipmSelection')
        )
      },
      {
        exact: true,
        path: '/app/operator/homeOperator/tag/create/:id?',
        component: lazy(() => import('src/views/planning/newTag/index'))
      },

    
    
    ]
  },
  {
    path: '/app/tablet',
    guard: AuthGuard,
    layout: TabletLayout,
    routes: [
      {
        exact: true,
        path: '/app/tablet/mgmt/hitsmaint',
        component: lazy(() => import('src/views/mgmt/hitsmaintenance'))
      },
      {
        exact: true,
        path: '/app/tablet/homeTablet',
        component: lazy(() =>
          import('src/views/execution/homeExec/MachinesView')
        )
      },
      {
        exact: true,
        path: '/app/tablet/orders/:cycleId',
        component: lazy(() =>
          import('src/views/execution/execOrders/OrdersEditView')
        )
      },
      {
        exact: true,
        path: '/app/tablet/orders/edit/id=:id/idsmp=:idsmp',
        component: lazy(() =>
          import('src/views/execution/execOrders/OrdersEditView/editForm')
        )
      },
      {
        exact: true,
        path: '/app/tablet/smp/detail/:smpId',
        component: lazy(() =>
          import('src/views/mgmt/methodSMP/SMPDetailsView')
        )
      },
      
      {
        exact: true,
        path: '/app/tablet/newEwo',
        component: lazy(() =>
          import('src/views/planning/newEwo/equipmSelection')
        )
      },
      {
        exact: true,
        path: '/app/tablet/newTag',
        component: lazy(() =>
          import('src/views/planning/newTag/equipmSelection')
        )
      },   

      {
        exact: true,
        path: '/app/tablet/ewo/intervention/',
        component: lazy(() =>
          import('src/views/planning/InterventionEwo/WizardAssignView')
        )
      },
      {
        exact: true,
        path: '/app/tablet/ewo/intervention/view',
        component: lazy(() =>
          import('src/views/planning/InterventionEwo/WizardView')
        )
      },
      //report lato manutentore
      {
        exact: true,
        path: '/app/tablet/tag/intervention/view',
        component: lazy(() =>
          import('src/views/planning/InterventionTag/WizardView')
        )
      },
      
      {
        exact: true,
        path: '/app/tablet/tag/intervention/',
        component: lazy(() =>
          import('src/views/planning/InterventionTag/WizardAssignView')
        )
      },

      //opening tag-ewo from tablet

      {
        exact: true,
        path: '/app/tablet/homeTablet/newEwo',
        component: lazy(() =>
          import('src/views/planning/newEwo/equipmSelection')
        )
      },
      {
        exact: true,
        path: '/app/tablet/homeTablet/ewo/create/:id?',
        component: lazy(() => import('src/views/planning/newEwo/index'))
      },

      {
        exact: true,
        path: '/app/tablet/homeTablet/newTag',
        component: lazy(() =>
          import('src/views/planning/newTag/equipmSelection')
        )
      },
      {
        exact: true,
        path: '/app/tablet/homeTablet/tag/create/:id?',
        component: lazy(() => import('src/views/planning/newTag/index'))
      },
      //{
      //  exact: true,
      //  path: '/app/tablet/WorkordersHistory',
      //  component: lazy(() => import('src/views/mgmt/WorkordersHistory'))
      //},
      //{
      //  exact: true,
      //  path: '/app/tablet/TagHistory',
      //  component: lazy(() => import('src/views/planning/TagHistory/Tag'))
      //},
      {
        exact: true,
        path: '/app/tablet/RootCauseAnalysis',
        component: lazy(() => import('src/views/order/RootAnalysisListView'))
      },
      {
        exact: true,
        path: '/app/tablet/RootCauseAnalysis/confirm/:idewo',
        component: lazy(() => import('src/views/order/RootAnalysisConfirmView'))
      },
      {
        exact: true,
        path: '/app/tablet/RootCauseAnalysis/confirm/:idewo',
        component: lazy(() => import('src/views/order/RootAnalysisConfirmView'))
      },
      {
        exact: true,
        path: '/app/tablet/RootCauseAnalysis/:idewo',
        component: lazy(() =>
          import('src/views/order/RootAnalisysSections/index')
        )
      },

    
    
    ]
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/plant',
        component: lazy(() => import('src/views/account/AccountView/ChoicePlantLogin/index'))
      },
      {
        exact: true,
        path: '/app/configuration/equipmentArchives/libMachineTipology',
        component: lazy(() =>
          import('src/views/configuration/equipmentArchives/libMachineTipology')
        )
      },
      {
        exact: true,
        path: '/app/configuration/equipmentArchives/libGroup',
        component: lazy(() =>
          import('src/views/configuration/equipmentArchives/libGroup')
        )
      },
      {
        exact: true,
        path: '/app/configuration/equipmentArchives/libSubgroup',
        component: lazy(() =>
          import('src/views/configuration/equipmentArchives/libSubgroup')
        )
      },
      {
        exact: true,
        path: '/app/configuration/equipmentArchives/libComponent',
        component: lazy(() =>
          import('src/views/configuration/equipmentArchives/libComponent')
        )
      },
      //altre librerie
      {
        exact: true,
        path: '/app/configuration/othersArchives/libLubrificant',
        component: lazy(() =>
          import('src/views/configuration/othersArchives/libLubricantTipology')
        )
      },
      {
        exact: true,
        path: '/app/configuration/othersArchives/libLubrificant/Edit/:idGroup',
        component: lazy(() =>
          import('src/views/configuration/othersArchives/libLubricantTipology/editView')
        )
      },
      {
        exact: true,
        path: '/app/configuration/othersArchives/libTools',
        component: lazy(() =>
          import('src/views/configuration/othersArchives/libToolsTipology')
        )
      },
      {
        exact: true,
        path: '/app/configuration/othersArchives/libMaterials',
        component: lazy(() =>
          import('src/views/configuration/othersArchives/libMaterialsTipology')
        )
      },
      {
        exact: true,
        path: '/app/configuration/othersArchives/libControlTools',
        component: lazy(() =>
          import('src/views/configuration/othersArchives/libCtrlToolsTipology')
        )
      },
      {
        exact: true,
        path: '/app/configuration/othersArchives/libPhisicalQty',
        component: lazy(() =>
          import('src/views/configuration/othersArchives/libPhisicalQty')
        )
      },
      {
        exact: true,
        path: '/app/configuration/othersArchives/libManufacturer',
        component: lazy(() =>
          import(
            'src/views/configuration/othersArchives/libManufacturer/libManufacturerListView'
          )
        )
      },
      {
        exact: true,
        path:
          '/app/configuration/othersArchives/libManufacturer/edit/:supplierId',
        component: lazy(() =>
          import(
            'src/views/configuration/othersArchives/libManufacturer/libManufacturerEditView'
          )
        )
      },
      {
        exact: true,
        path: '/app/configuration/othersArchives/libManufacturer/create',
        component: lazy(() =>
          import(
            'src/views/configuration/othersArchives/libManufacturer/libManufacturerCreateView'
          )
        )
      },
      {
        exact: true,
        path: '/app/configuration/controls',
        component: lazy(() =>
          import('src/views/configuration/Controls/ControlsListView')
        )
      },
      {
        exact: true,
        path: '/app/configuration/controls/create',
        component: lazy(() =>
          import('src/views/configuration/Controls/ControlsCreateView')
        )
      },
      {
        exact: true,
        path: '/app/configuration/controls/Edit/:controlId',
        component: lazy(() =>
          import('src/views/configuration/Controls/ControlsEditView')
        )
      },
      {
        exact: true,
        path: '/app/configuration/controls/detail/:controlId',
        component: lazy(() =>
          import('src/views/configuration/Controls/ControlsDetailsView')
        )
      },
      {
        exact: true,
        path: '/app/configuration/users',
        component: lazy(() =>
          import('src/views/configuration/Users/UsersListView')
        )
      },
      {
        exact: true,
        path: '/app/configuration/users/edit/:userId?',
        component: lazy(() =>
          import('src/views/configuration/Users/UsersEditView')
        )
      },
      {
        exact: true,
        path: '/app/configuration/users/create',
        component: lazy(() =>
          import('src/views/configuration/Users/UsersCreateView')
        )
      },
      {
        exact: true,
        path: '/app/configuration/dataingestion',
        component: lazy(() =>
          import('src/views/dataIngestion')
        )
      },
      {
        exact: true,
        path: '/app/mgmt/smp/detail/:smpId',
        component: lazy(() => import('src/views/mgmt/methodSMP/SMPDetailsView'))
      },
      {
        exact: true,
        path: '/app/mgmt/smp',
        component: lazy(() => import('src/views/mgmt/methodSMP/SMPListView'))
      },
      {
        exact: true,
        path: '/app/mgmt/smp/create',
        component: lazy(() => import('src/views/mgmt/methodSMP/SMPCreateView'))
      },
      {
        exact: true,
        path: '/app/mgmt/smp/edit/:smpId',
        component: lazy(() => import('src/views/mgmt/methodSMP/SMPEditView'))
      },
      {
        exact: true,
        path: '/app/mgmt/smp/detail/:smpId',
        component: lazy(() => import('src/views/mgmt/methodSMP/SMPDetailsView'))
      },
      //passo come parametro la tipologia dell'SMP
      {
        exact: true,
        path: '/app/mgmt/phase/create/:smpId?',
        component: lazy(() =>
          import('src/views/mgmt/SMPPhase/SMPPhaseCreateView/PhaseCreateForm')
        )
      },
      {
        exact: true,
        path: '/app/mgmt/phase/edit/:idphase?',
        component: lazy(() =>
          import('src/views/mgmt/SMPPhase/SMPPhaseEditView/PhaseEditView')
        )
      },
      /////// CHECKLIST
      {
        exact: true,
        path: '/app/mgmt/checklist',
        component: lazy(() => import('src/views/mgmt/checklist/checklistView'))
      },
      {
        exact: true,
        path: '/app/mgmt/checklist/detail/:smpId',
        component: lazy(() => import('src/views/mgmt/checklist/checklistDetailsView'))
      },
      ////// AM
      {
        exact: true,
        path: '/app/mgmtAM/AMReporting',
        component: lazy(() => import('src/views/mgmtAM/rendicontazioneAM/index'))
      },
      {
        exact: true,
        path: '/app/mgmtAM/AMCalendar',
        component: lazy(() => import('src/views/mgmtAM/AMcalendar/equipmSelection'))
      },
      
      {
        exact: true,
        path: '/app/mgmtAM/AMCalendar/view/:id',
        component: lazy(() =>
          import('src/views/mgmtAM/AMcalendar')
        )
      },
      {
        exact: true,
        path: '/app/mgmtAM/sop',
        component: lazy(() => import('src/views/mgmtAM/methodSOP/SOPListView'))
      },
      {
        exact: true,
        path: '/app/mgmtAM/sop/create',
        component: lazy(() =>
          import('src/views/mgmtAM/methodSOP/SOPCreateView')
        )
      },
      {
        exact: true,
        path: '/app/mgmtAM/sop/edit/:sopId',
        component: lazy(() => import('src/views/mgmtAM/methodSOP/SOPEditView'))
      },
      {
        exact: true,
        path: '/app/mgmtAM/sop/detail/:sopId',
        component: lazy(() =>
          import('src/views/mgmtAM/methodSOP/SOPDetailsView')
        )
      },

      {
        exact: true,
        path: '/app/mgmtAM/phase/create/:sopId?',
        component: lazy(() =>
          import('src/views/mgmtAM/SOPPhase/SOPPhaseCreateView/PhaseCreateForm')
        )
      },
      {
        exact: true,
        path: '/app/mgmtAM/phase/edit/:idphase?',
        component: lazy(() =>
          import('src/views/mgmtAM/SOPPhase/SOPPhaseEditView/PhaseEditView')
        )
      },
      {
        exact: true,
        path: '/app/mgmt/smp/detail/:smpId',
        component: lazy(() => import('src/views/mgmt/methodSMP/SMPDetailsView'))
      },

      {
        exact: true,
        path: '/app/mgmtAM/maintenance',
        component: lazy(() =>
          import(
            'src/views/mgmtAM/maintenanceAM/strategyView/equipmSelection'
          )
        )
      },

      {
        exact: true,
        path: '/app/mgmtAM/maintenance',
        component: lazy(() =>
          import(
            'src/views/mgmtAM/maintenanceAM/strategyView/equipmSelection'
          )
        )
      },


      {
        exact: true,
        path: '/app/mgmtAM/maintenance/structure/:machineId?',
        component: lazy(() =>
          import('src/views/mgmtAM/maintenanceAM/strategyView/EquipmStructureView')
        )
      },
  

      {
        //elementid=:elementId&elemidtipology=:elementIdTypology&elemidmachine=:elementidmachine
        exact: true,
        path: '/app/mgmtAM/maintenance/soptipology/:sopTypology?',
        component: lazy(() =>
          import('src/views/mgmtAM/maintenanceAM/strategyView/SOPListView')
        )
      },
      {
        //elementid=:elementId/elemidtipology=:elementIdTypology/elemidmachine=:elementIdMachine',
        exact: true,
        path: '/app/mgmtAM/maintenance/soptipologyadapted/:sopTypology?',
        component: lazy(() =>
          import(
            'src/views/mgmtAM/maintenanceAM/strategyView/SOPAdaptedListView'
          )
        )
      },
      {
        //elementid=:elementId/elemidmachine=:elementIdMachine
        exact: true,
        path: '/app/mgmtAM/maintenance/soptipology/create/:sopId?',
        component: lazy(() =>
          import('src/views/mgmtAM/maintenanceAM/strategyView/SOPCreateView')
        )
      },
      {
        ///elementid=:elementId/elemidmachine=:elementIdMachine
        exact: true,
        path: '/app/mgmtAM/maintenance/soptipologyadapted/edit/:sopId?',
        component: lazy(() =>
          import('src/views/mgmtAM/maintenanceAM/strategyView/SOPEditView')
        )
      },

      {
        exact: true,
        path: '/app/mgmtAM/path/equipSelection',
        component: lazy(() => import('src/views/mgmtAM/pathSOP/equipmSelection'))
      },

      {
        exact: true,
        path: '/app/mgmtAM/path/:machineId?',
        component: lazy(() => import('src/views/mgmtAM/pathSOP/ListView'))
      },
      {
        exact: true,
        path: '/app/mgmtAM/path/create',
        component: lazy(() => import('src/views/mgmtAM/pathSOP/CreateView'))
      },
      {
        exact: true,
        path: '/app/mgmtAM/path/edit/:pathid?',
        component: lazy(() => import('src/views/mgmtAM/pathSOP/EditView'))
      },

      {
        exact: true,
        path: '/app/mgmtAM/cilr/equipSelection',
        component: lazy(() => import('src/views/mgmtAM/CILRpath/equipmSelection'))
      },

      {
        exact: true,
        path: '/app/mgmtAM/cilr/:machineId?',
        component: lazy(() => import('src/views/mgmtAM/CILRpath/ListView'))
      },
      {
        exact: true,
        path: '/app/mgmtAM/cilr/create',
        component: lazy(() => import('src/views/mgmtAM/CILRpath/CreateView'))
      },
      {
        exact: true,
        path: '/app/mgmtAM/cilr/edit/:cilrid?',
        component: lazy(() => import('src/views/mgmtAM/CILRpath/EditView'))
      },

      //////PM

      {
        exact: true,
        path: '/app/mgmt/catalogs/controltools',
        component: lazy(() =>
          import('src/views/mgmt/catalogs/Control_Instruments')
        )
      },
      {
        exact: true,
        path: '/app/mgmt/catalogs/tools',
        component: lazy(() => import('src/views/mgmt/catalogs/tools'))
      },
      {
        exact: true,
        path: '/app/mgmt/catalogs/auxiliarymaterials',
        component: lazy(() => import('src/views/mgmt/catalogs/Materials'))
      },
      {
        exact: true,
        path: '/app/mgmt/catalogs/lubfrificants',
        component: lazy(() => import('src/views/mgmt/catalogs/Lubrificant'))
      },
      {
        exact: true,
        path: '/app/mgmt/catalogs/material/edit/:id?',
        component: lazy(() =>
          import('src/views/mgmt/catalogs/EditAndCreateMaterial/EditMaterial')
        )
      },
      {
        exact: true,
        path: '/app/mgmt/catalogs/material/create/:idtyperec',
        component: lazy(() =>
          import('src/views/mgmt/catalogs/EditAndCreateMaterial/CreateMaterial')
        )
      },
      {
        exact: true,
        path: '/app/mgmt/equipStructure',
        component: lazy(() =>
          import('src/views/mgmt/equipStructure/equipmSelection')
        )
      },
      {
        exact: true,
        path: '/app/mgmtAM/equipStructure/equipSelection',
        component: lazy(() =>
          import('src/views/mgmt/equipStructure/equipmSelection')
        )
      },
      

      {
        exact: true,
        path: '/app/mgmt/equipStructure/:machineId?',
        component: lazy(() => import('src/views/mgmt/equipStructure'))
      },
      {
        exact: true,
        path: '/app/mgmt/equipStructure/edit/:machineId',
        component: lazy(() =>
          import('src/views/mgmt/equipStructure/MachineEditView')
        )
      },
      {
        exact: true,
        path: '/app/mgmt/equipStructure/view/:machineId',
        component: lazy(() =>
          import(
            'src/views/mgmt/maintenance/strategyView/EquipmStructureDetailsView'
          )
        )
      },
      {
        exact: true,
        path: '/app/mgmt/equipStructure/viewmac/:machineId',
        component: lazy(() =>
          import(
            'src/views/mgmt/equipStructure/EquipmStructureDetailsView'
          )
        )
      },
      {
        exact: true,
        path: '/app/mgmt/equipStructure/create/:machineId?',
        component: lazy(() =>
          import('src/views/mgmt/equipStructure/ElementCreateView')
        )
      },
      {
        exact: true,
        path: '/app/mgmt/maintenance',
        component: lazy(() =>
          import('src/views/mgmt/maintenance/strategyView/equipmSelection')
        )
      },
      {
        exact: true,
        path: '/app/mgmt/maintenance/structure/view/:machineId',
        component: lazy(() =>
          import(
            'src/views/mgmt/maintenance/strategyView/EquipmStructureDetailsView'
          )
        )
      },
      {
        exact: true,
        path: '/app/mgmt/maintenance/structure/:machineId?',
        component: lazy(() =>
          import('src/views/mgmt/maintenance/strategyView/EquipmStructureView')
        )
      },
      {
        exact: true,
        path: '/app/mgmt/hitsmaint',
        component: lazy(() => import('src/views/mgmt/hitsmaintenance'))
      },
      {
        exact: true,
        path:
          '/app/mgmt/equipStructure/editElement/:elementId/:elementLevel/:elementSpare',
        component: lazy(() =>
          import('src/views/mgmt/equipStructure/ElementEditView')
        )
      },
      {
        //elementid=:elementId&elemidtipology=:elementIdTypology&elemidmachine=:elementidmachine
        exact: true,
        path: '/app/mgmt/maintenance/smptipology/:smpTypology?',
        component: lazy(() =>
          import('src/views/mgmt/maintenance/strategyView/SMPListView')
        )
      },
      //{
      //  //elementid=:elementId&elemidtipology=:elementIdTypology&elemidmachine=:elementidmachine
      //  exact: true,
      //  path: '/app/mgmt/maintenance/checklisttipology/:smpTypology?',
      //  component: lazy(() =>
      //    import('src/views/mgmt/maintenance/strategyView/ChecklistListView')
      //  )
      //},
      {
        //elementid=:elementId&elemidtipology=:elementIdTypology&elemidmachine=:elementidmachine
        exact: true,
        path: '/app/mgmt/maintenance/checklisttipology/:smpTypology?',
        component: lazy(() =>
          import('src/views/mgmt/maintenance/strategyView/CHKListView')
        )
      },
      {
        //elementid=:elementId/elemidtipology=:elementIdTypology/elemidmachine=:elementIdMachine',
        exact: true,
        path: '/app/mgmt/maintenance/checklisttipologyadapted/:smpTypology?',
        component: lazy(() =>
          import('src/views/mgmt/maintenance/strategyView/CHKAdaptedListView')
        )
      },
      {
        //elementid=:elementId/elemidtipology=:elementIdTypology/elemidmachine=:elementIdMachine',
        exact: true,
        path: '/app/mgmt/maintenance/smptipologyadapted/:smpTypology?',
        component: lazy(() =>
          import('src/views/mgmt/maintenance/strategyView/SMPAdaptedListView')
        )
      },
      {
        //elementid=:elementId/elemidtipology=:elementIdTypology/elemidmachine=:elementIdMachine',
        exact: true,
        path: '/app/mgmt/maintenance/smpdetail/:smpId?',
        component: lazy(() =>
          import('src/views/mgmt/maintenance/strategyView/SMPDetailsView')
        )
      },
      {
        //elementid=:elementId/elemidtipology=:elementIdTypology/elemidmachine=:elementIdMachine',
        exact: true,
        path: '/app/mgmt/maintenance/chkdetail/:smpId?',
        component: lazy(() =>
          import('src/views/mgmt/maintenance/strategyView/CHKDetailsView')
        )
      },
      {
        //elementid=:elementId/elemidmachine=:elementIdMachine
        exact: true,
        path: '/app/mgmt/maintenance/smptipology/create/:smpId?',
        component: lazy(() =>
          import('src/views/mgmt/maintenance/strategyView/SMPCreateView')
        )
      },
      {
        //elementid=:elementId/elemidmachine=:elementIdMachine
        exact: true,
        path: '/app/mgmt/maintenance/checklisttipology/create/:smpId?',
        component: lazy(() =>
          import('src/views/mgmt/maintenance/strategyView/CHKCreateView')
        )
      },
      {
        ///elementid=:elementId/elemidmachine=:elementIdMachine
        exact: true,
        path: '/app/mgmt/maintenance/smptipologyadapted/edit/:smpId?',
        component: lazy(() =>
          import('src/views/mgmt/maintenance/strategyView/SMPEditView')
        )
      },
      {
        ///elementid=:elementId/elemidmachine=:elementIdMachine
        exact: true,
        path: '/app/mgmt/maintenance/chktipologyadapted/edit/:smpId?',
        component: lazy(() =>
          import('src/views/mgmt/maintenance/strategyView/CHKEditView')
        )
      },
      {
        //?smpid=[]&elementIdmachine=[]
        exact: true,
        path: '/app/mgmt/maintenance/smptipologyadapted/phase/edit/:phaseId',
        component: lazy(() =>
          import(
            'src/views/mgmt/maintenance/strategyView/SMPEditView/Phases/PhasesEditView'
          )
        )
      },
      {
        //?smpid=[]&elementIdmachine=[]
        exact: true,
        path: '/app/mgmt/maintenance/chktipologyadapted/phase/edit/:phaseId',
        component: lazy(() =>
          import(
            'src/views/mgmt/maintenance/strategyView/CHKEditView/Phases/PhasesEditView'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/plants',
        component: lazy(() => import('src/views/plant/PlantListView'))
      },
      ///root cause analysis
      {
        exact: true,
        path: '/app/management/RootCauseAnalysis',
        component: lazy(() => import('src/views/order/RootAnalysisListView'))
      },
      {
        exact: true,
        path: '/app/management/RootCauseAnalysis/:idewo',
        component: lazy(() =>
          import('src/views/order/RootAnalisysSections/index')
        )
      },
      {
        exact: true,
        path: '/app/management/RootCauseAnalysis/confirm/:idewo',
        component: lazy(() => import('src/views/order/RootAnalysisConfirmView'))
      },
      {
        exact: true,
        path: '/app/management/libskill/capacity',
        component: lazy(() =>
          import('src/views/libreria_skill/Capacity/ListView')
        )
      },
      {
        exact: true,
        path: '/app/management/libskill/capacity/detail/:idCapacity',
        component: lazy(() =>
          import('src/views/libreria_skill/Capacity/EditView')
        )
      },
      {
        exact: true,
        path: '/app/management/libskill/capacity/edit/:idCapacity',
        component: lazy(() =>
          import('src/views/libreria_skill/Capacity/EditView')
        )
      },
      {
        exact: true,
        path: '/app/management/libskill/capacity/create',
        component: lazy(() =>
          import('src/views/libreria_skill/Capacity/CreateView')
        )
      },
      {
        exact: true,
        path: '/app/management/libskill/professionalgroups',
        component: lazy(() =>
          import('src/views/libreria_skill/ProfessionalGroups/ListView')
        )
      },
      {
        exact: true,
        path: '/app/management/libskill/professionalgroups/create',
        component: lazy(() =>
          import('src/views/libreria_skill/ProfessionalGroups/CreateView')
        )
      },
      {
        exact: true,
        path: '/app/management/libskill/professionalgroups/edit/:idGroup',
        component: lazy(() =>
          import('src/views/libreria_skill/ProfessionalGroups/EditView')
        )
      },
      {
        exact: true,
        path: '/app/management/libskill/tipology',
        component: lazy(() =>
          import('src/views/libreria_skill/Tipology/ListView')
        )
      },
      {
        exact: true,
        path: '/app/management/libskill/tipology/create',
        component: lazy(() =>
          import('src/views/libreria_skill/Tipology/CreateView')
        )
      },
      {
        exact: true,
        path: '/app/management/libskill/tipology/edit/:idTip',
        component: lazy(() =>
          import('src/views/libreria_skill/Tipology/EditView')
        )
      },
      // sezione planning
      {
        exact: true,
        path: '/app/planning/maintenanceCycles/view/:typeCycle',
        component: lazy(() =>
          import('src/views/planning/maintenanceCycles/CyclesListView')
        )
      },
      {
        exact: true,
        path: '/app/planning/maintenanceCycles/create/:typeCycle',
        component: lazy(() =>
          import('src/views/planning/maintenanceCycles/CyclesCreateView/equipmSelection')
        )
      },

      {
        exact: true,
        path: '/app/planning/maintenanceCycles/create/new/:typeCycle',
        component: lazy(() =>
          import('src/views/planning/maintenanceCycles/CyclesCreateView')
        )
      },

      {
        exact: true,
        path: '/app/planning/maintenanceCycles/edit/:typeCycle/:IdCycle',
        component: lazy(() =>
          import('src/views/planning/maintenanceCycles/CyclesEditView')
        )
      },

      {
        exact: true,
        path: '/app/planning/maintenanceCycles/view',
        component: lazy(() =>
          import('src/views/planning/maintenanceCycles/CyclesListView')
        )
      },

      {
        exact: true,
        path: '/app/calendar/lubricant',
        component: lazy(() => import('src/views/mgmt/AMcalendar'))
      },
      {
        exact: true,
        path: '/app/planning/mgmtOperators',
        component: lazy(() =>
          import('src/views/planning/mgmtOperators/OperatorsListView')
        )
      },
      {
        exact: true,
        path: '/app/planning/mgmtOperators/create',
        component: lazy(() =>
          import('src/views/planning/mgmtOperators/CreateView/')
        )
      },
      {
        exact: true,
        path: '/app/planning/mgmtOperators/edit/:idOper',
        component: lazy(() =>
          import('src/views/planning/mgmtOperators/EditView/index')
        )
      },
      {
        exact: true,
        path: '/app/planning/mgmtTeams',
        component: lazy(() =>
          import('src/views/planning/mgmtTeams/TeamsListView')
        )
      },
      {
        exact: true,
        path: '/app/planning/mgmtTeams/edit/:IdTeam',
        component: lazy(() =>
          import('src/views/planning/mgmtTeams/EditView/index')
        )
      },
      {
        exact: true,
        path: '/app/planning/mgmtTeams/create',
        component: lazy(() =>
          import('src/views/planning/mgmtTeams/CreateView/index')
        )
      },
      //DEMO PLANT LAYOUT
      {
        exact: true,
        path: '/app/plantlayout',
        component: lazy(() => import('src/views/mgmt/PlantLayout/index'))
      },
      /// rendicontazione ordini

      {
        exact: true,
        path: '/app/execution/homeExec',
        component: lazy(() =>
          import('src/views/execution/homeExec/MachinesView')
        )
      },
      // {
      //   exact: true,
      //   path: '/app/execution/home',
      //   component: lazy(() =>
      //     import('src/views/execution/execInterventions/MachinesView')
      //   )
      // },
  /*     {
        exact: true,
        path: '/app/execution/orders/:cycleId',
        component: lazy(() =>
          import('src/views/execution/execOrders/OrdersEditView')
        )
      }, */
      {
        exact: true,
        path: '/app/mgmt/PMcalendar',
        component: lazy(() => import('src/views/mgmt/PMcalendar'))
      },
      {
        exact: true,
        path: '/app/mgmt/CKcalendar',
        component: lazy(() => import('src/views/mgmt/CKcalendar'))
      },
      {
        exact: true,
        path: '/app/mgmt/workorders',
        component: lazy(() => import('src/views/mgmt/Workorders'))
      },
      {
        exact: true,
        path: '/app/mgmt/reportChecklist',
        component: lazy(() => import('src/views/mgmt/ReportChecklist'))
      },
      {
        exact: true,
        path: '/app/mgmt/PMcalendar/:idCycle',
        component: lazy(() => import('src/views/mgmt/PMcalendar/SMPViewCalendar/index'))
      },
      {
        exact: true,
        path: '/app/mgmt/PMcalendar/smpDetail/:smpId',
        component: lazy(() =>
        import('src/views/mgmt/maintenance/strategyView/SMPDetailsView'))
      },
      {
        exact: true,
        path: '/app/mgmt/CKcalendar/:idCycle',
        component: lazy(() => import('src/views/mgmt/CKcalendar/CHKViewCalendar/index'))
      },
      {
        exact: true,
        path: '/app/mgmt/CKcalendar/chkDetail/:smpId',
        component: lazy(() =>
          import('src/views/mgmt/maintenance/strategyView/CHKDetailsView'))
      },
      {
        exact: true,
        path: '/app/mgmt/MachineLedger',
        component: lazy(() =>
          import('src/views/mgmt/machineLedger/MachinesView/equipmSelection')
        )
      },
      {
        exact: true,
        path: '/app/mgmt/MachineLedger/view/:id',
        component: lazy(() =>
          import('src/views/mgmt/machineLedger/MachinesView')
        )
      },
      {
        exact: true,
        path: '/app/mgmt/warehouse',
        component: lazy(() => import('src/views/mgmt/warehouse/CardGridView/Index'))
      },
      {
        exact: true,
        path: '/app/mgmt/warehouse/create',
        component: lazy(() => import('src/views/mgmt/warehouse/WrCreateView/index'))
      },
      {
        exact: true,
        path: '/app/mgmt/warehouse/edit/:Id',
        component: lazy(() => import('src/views/mgmt/warehouse/WrEditView/index'))
      },
      {
        exact: true,
        path: '/app/mgmt/warehouse/spareparts/:Id',//id magazzino 
        component: lazy(() => import('src/views/mgmt/warehouse/SpareListView/index'))
      },
      {
        exact: true,
        path: '/app/mgmt/warehouse/spareparts/create/:Id',//id magazzino
        component: lazy(() => import('src/views/mgmt/warehouse/SpareAddView/index'))
      },
      {
        exact: true,
        path: '/app/mgmt/warehouse/spareparts/edit/:codespare',//codice ricambio
        component: lazy(() => import('src/views/mgmt/warehouse/SpareEditView/index'))
      },
      {
        exact: true,
        path: '/app/mgmt/warehouse/spareparts/detail/:codespare',//codice ricambio
        component: lazy(() => import('src/views/mgmt/warehouse/SpareDetailView/index'))
      },
      {
        exact: true,
        path: '/app/mgmt/PMlubricant',
        component: lazy(() => import('src/views/mgmt/PMLubricant'))
      },
      /// rendicontazione ewo
      {
        exact: true,
        path: '/app/planning/ewo/interventionewo',
        component: lazy(() =>
          import('src/views/planning/InterventionEwo/EwoIntervention')
        )
      },
      {
        exact: true,
        path: '/app/execution/tag',
        component: lazy(() =>
          import('src/views/execution/execTag/MachinesView')
        )
      },
      //list
      {
        exact: true,
        path: '/app/planning/workorder/assign',
        component: lazy(() => import('src/views/planning/workOrders'))
      },
      {
        exact: true,
        path: '/app/planning/ewo/assign',
        component: lazy(() => import('src/views/planning/assignEwoTag/Ewo'))
      },
      {
        exact: true,
        path: '/app/planning/tag/assign',
        component: lazy(() => import('src/views/planning/assignEwoTag/Tag'))
      },
      {
        exact: true,
        path: '/app/planning/tag/Reporting/:id?',
        component: lazy(() => import('src/views/planning/assignEwoTag/Tag/ReportTag'))
      },
      //?id=1&machinecode=M0160797
      {
        exact: true,
        path: '/app/planning/ewo/equipment',
        component: lazy(() =>
          import('src/views/planning/newEwo/equipmSelection')
        )
      },
      {
        exact: true,
        path: '/app/planning/ewo/ewohistory',
        component: lazy(() => import('src/views/planning/EwoHistory/index'))
      },
      {
        exact: true,
        path: '/app/planning/ewo/create/:id?',
        component: lazy(() => import('src/views/planning/newEwo/index'))
      },
      //TAG
      {
        exact: true,
        path: '/app/planning/tag/equipment',
        component: lazy(() =>
          import('src/views/planning/newTag/equipmSelection')
        )
      },
      {
        exact: true,
        path: '/app/planning/tag/create/:id?',
        component: lazy(() => import('src/views/planning/newTag/index'))
      },
      //?idewo=1
      {
        exact: true,
        path: '/app/planning/ewo/intervention/',
        component: lazy(() =>
          import('src/views/planning/InterventionEwo/WizardAssignView')
        )
      },
      {
        exact: true,
        path: '/app/planning/ewo/intervention/view',
        component: lazy(() =>
          import('src/views/planning/InterventionEwo/WizardView')
        )
      },
      // report lato admin
      {
        exact: true,
        path: '/app/planning/tag/intervention/view',
        component: lazy(() =>
          import('src/views/planning/InterventionTag/WizardView')
        )
      },
      {
        exact: true,
        path: '/app/planning/tag/intervention/',
        component: lazy(() =>
          import('src/views/planning/InterventionTag/WizardAssignView')
        )
      },
      {
        exact: true,
        path: '/app/planning/orders/:cycleId',
        component: lazy(() =>
          import('src/views/execution/execOrders/OrdersEditView')
        )
      },
      {
        exact: true,
        path: '/app/planning/orders/edit/id=:id/idsmp=:idsmp',
        component: lazy(() =>
          import('src/views/execution/execOrders/OrdersEditView/editForm')
        )
      },
      
/*       {
        exact: true,
        path: '/app/planning/ewo/intervention/view',
        component: lazy(() =>
          import('src/views/planning/InterventionEwo/WizardView')
        )
      },
      {
        exact: true,
        path: '/app/planning/tag/intervention/view',
        component: lazy(() =>
          import('src/views/planning/InterventionTag/WizardView')
        )
      }, */

      {
        exact: true,
        path: '/app/reports/dashboard',
        component: lazy(() => import('src/views/reports/DashboardView'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard-alternative',
        component: lazy(() =>
          import('src/views/reports/DashboardAlternativeView')
        )
      },
      {
        exact: true,
        path: '/app/reports/redirect-ext',
        component: lazy(() =>
          import('src/views/reports/RedirectExtView')
        )
      },
      {
        exact: true,
        path: '/app/reports',
        component: () => <Redirect to="/app/reports/dashboard" />
      },

      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/reports/dashboard-alternative" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },

  
  {
    path: '/docs',
    guard: AuthGuard,
    layout: DocsLayout,
    routes: [
      {
        exact: true,
        path: '/docs',
        component: () => <Redirect to="/docs/welcome" />
      },
      {
        exact: true,
        path: '/docs/welcome',
        component: lazy(() => import('src/views/docs/WelcomeView'))
      },
      {
        exact: true,
        path: '/docs/getting-started',
        component: lazy(() => import('src/views/docs/GettingStartedView'))
      },
      {
        exact: true,
        path: '/docs/global/user',
        component: lazy(() => import('src/views/docs/Global/User'))
      },
      {
        exact: true,
        path: '/docs/global/login',
        component: lazy(() => import('src/views/docs/Global/Login'))
      },
      {
        exact: true,
        path: '/docs/global/functionalities',
        component: lazy(() => import('src/views/docs/Global/GlobalFunctionalities'))
      },
      {
        exact: true,
        path: '/docs/PlantLayout',
        component: lazy(() => import('src/views/docs/Plantlayout'))
      },
      {
        exact: true,
        path: '/docs/PMmgmt/workflow',
        component: lazy(() => import('src/views/docs/PMmgmt/workflow'))
      },
      {
        exact: true,
        path: '/docs/PMmgmt/structure',
        component: lazy(() => import('src/views/docs/PMmgmt/structure'))
      },
      {
        exact: true,
        path: '/docs/PMmgmt/smp',
        component: lazy(() => import('src/views/docs/PMmgmt/smp'))
      },
      {
        exact: true,
        path: '/docs/PMmgmt/strategy',
        component: lazy(() => import('src/views/docs/PMmgmt/strategy'))
      },
      {
        exact: true,
        path: '/docs/PMmgmt/ml',
        component: lazy(() => import('src/views/docs/PMmgmt/ml'))
      },
      {
        exact: true,
        path: '/docs/planning/workflow',
        component: lazy(() => import('src/views/docs/planning/workflow'))
      },
      {
        exact: true,
        path: '/docs/planning/routing',
        component: lazy(() => import('src/views/docs/planning/routing'))
      },
      {
        exact: true,
        path: '/docs/planning/teams',
        component: lazy(() => import('src/views/docs/planning/teams'))
      },
      {
        exact: true,
        path: '/docs/planning/technician',
        component: lazy(() => import('src/views/docs/planning/technicians'))
      },
      {
        exact: true,
        path: '/docs/planning/newewo',
        component: lazy(() => import('src/views/docs/planning/newewo'))
      },
      {
        exact: true,
        path: '/docs/planning/assignewo',
        component: lazy(() => import('src/views/docs/planning/assignewo'))
      },

      {
        exact: true,
        path: '/docs/planning/newtag',
        component: lazy(() => import('src/views/docs/planning/newtag'))
      },
      {
        exact: true,
        path: '/docs/planning/assigntag',
        component: lazy(() => import('src/views/docs/planning/assigntag'))
      },
      {
        exact: true,
        path: '/docs/reporting/pm',
        component: lazy(() => import('src/views/docs/reporting/pm'))
      },
      {
        exact: true,
        path: '/docs/reporting/ewo',
        component: lazy(() => import('src/views/docs/reporting/ewo'))
      },
      {
        exact: true,
        path: '/docs/reporting/tag',
        component: lazy(() => import('src/views/docs/reporting/tag'))
      },
    
      {
        exact: true,
        path: '/docs/deployment',
        component: lazy(() => import('src/views/docs/DeploymentView'))
      },
      {
        exact: true,
        path: '/docs/api-calls',
        component: lazy(() => import('src/views/docs/APICallsView'))
      },
      {
        exact: true,
        path: '/docs/analytics',
        component: lazy(() => import('src/views/docs/AnalyticsView'))
      },
      {
        exact: true,
        path: '/docs/authentication',
        component: lazy(() => import('src/views/docs/AuthenticationView'))
      },
      {
        exact: true,
        path: '/docs/routing',
        component: lazy(() => import('src/views/docs/planning'))
      },
      {
        exact: true,
        path: '/docs/settings',
        component: lazy(() => import('src/views/docs/Configuration'))
      },
      {
        exact: true,
        path: '/docs/state-management',
        component: lazy(() => import('src/views/docs/StateManagementView'))
      },
      {
        exact: true,
        path: '/docs/theming',
        component: lazy(() => import('src/views/docs/ThemingView'))
      },
      {
        exact: true,
        path: '/docs/support',
        component: lazy(() => import('src/views/docs/SupportView'))
      },
      {
        exact: true,
        path: '/docs/changelog',
        component: lazy(() => import('src/views/docs/ChangelogView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: HomeView
      },
      /*  {
        exact: true,
        path: '/pricing',
        component: lazy(() => import('src/views/pricing/PricingView'))
      }, */
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
