import React, {
  createContext,
  useEffect,
  useReducer,
  useContext
} from 'react';
import { Auth0Client } from '@auth0/auth0-spa-js';
import SplashScreen from 'src/components/SplashScreen';
import { auth0Config } from 'src/config';
import { PublicClientApplication } from "@azure/msal-browser";
// import { msalConfig, loginRequest, tokenRequest, silentRequest } from '../authConfig.js.prod/index.js';
import { msalConfig, loginRequest, tokenRequest, silentRequest } from '../authConfig.js';
import axios from 'src/utils/terotechDataservice';
import useAuth from 'src/hooks/useAuth';
import { AlertTriangle } from 'react-feather';
import jwtDecode from 'jwt-decode';
import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import useSettings from 'src/hooks/useSettings';
import * as dataSetting from 'src/dataservice/dataSetting';


let auth0Client = null;


const initialAuthState = {
  isAuthenticated: false,
  isAuthorized:false,
  isInitialised: false,
  user: null
};

const reducer = (state, action) => {
  
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, isAuthorized, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isAuthorized,
        isInitialised: true,
        user
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        isAuthorized:true,
        user
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        isAuthorized:false,
        user: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'Azure',
  loginWithPopup: () => Promise.resolve(),
  logout: () => { }
});

const setSession = (accessToken, username) => {
  
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('username', username);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('username');
    delete axios.defaults.headers.common.Authorization;
  }
};

export const AuthProvider = ({ children }) => {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const { user } =  useContext(AuthContext);
  const { settings, saveSettings } = useSettings();

  const loginWithPopup = async (options) => {
    
    msalApp.loginRedirect(loginRequest)
  };


  const logout = () => {
  
    const logoutRequest = {
      account: msalApp.getAccountByUsername("")
    };

    msalApp.logout(localStorage.getItem("username"));

    setSession(null);

    dispatch({ type: 'LOGOUT' });
  };



  useEffect(() => {
    
    const msalApp = new PublicClientApplication(msalConfig);
    
    const initialise = async () => {
      
      try {
        //const msalApp = new PublicClientApplication(msalConfig);
        msalConfig.auth.redirectUri = `${window.location.origin}/login`;
        msalApp.handleRedirectPromise().then((response)=>{
                //
                if (response !== null) {
                  setSession(response.accessToken, response.account.username);
                  axios.get('UserTenant/get').then(res1=>{
                    if (res1.status != 200) {

                      dispatch({
                        type: 'INITIALISE',
                        payload: {
                          isAuthenticated: true,
                          isAuthorized: false,
                          user: null
                        }
                      })

                    } else {

                      //if user is not in a root endpoint call context link to plant
                      if (res1.data.endpointRoot) {
                        dispatch({
                          type: 'INITIALISE',
                          payload: {
                            isAuthenticated: true,
                            isAuthorized: (res1.status == 200),
                            user: {
                              id: response.account.homeAccountId,
                              iduser: res1.data.id,
                              avatar: null,
                              email: response.account.username,
                              name: response.account.name,
                              endpointRoot: res1.data.endpointRoot,
                              tier: 'Premium'
                            }
                          }
                        });
                      } else {
                        //initialize context BE link to plant
                        axios.get('user/get').then(res => {
                          dispatch({
                            type: 'INITIALISE',
                            payload: {
                              isAuthenticated: true,
                              isAuthorized: (res.status == 200),
                              user: {
                                id: response.account.homeAccountId,
                                avatar: null,
                                idprofilo: res.data.idprofilo,
                                iduser: res.data.id,
                                idplant: res.data.idplant,
                                idregion: res.data.plant.idRegion,
                                plantdesc: res.data.plant.descrizione,
                                language: res.data.plant.lang,
                                email: response.account.username,
                                name: response.account.name,
                                endpointRoot: res1.data.endpointRoot,
                                gestioneRisorse: res.data.plant.risorse,
                                tier: 'Premium'
                              }
                            }
                          });
                          //settings
                          if (!window.localStorage.getItem("settings")) {
                            dataSetting.get().then((resp) => {
                              if (resp.data) {
                                saveSettings(resp.data)
                              }
                            }).catch(err => {
                              console.error(err)
                            });
                          }

                          // user/get =>error
                        }).catch(function (error) {
                          console.error(error)
                          dispatch({
                            type: 'INITIALISE',
                            payload: {
                              isAuthenticated: true,
                              isAuthorized: false,
                              user: null
                              // user: {
                              //   id: response.account.homeAccountId,
                              //   avatar: null,
                              //   email: response.account.username,
                              //   name: response.account.name,
                              //   tier: 'Premium'
                              // }
                            }
                          });
                        });

                      }

                    }

                    //. /TenantUser/get => error
                  }).catch(function (error) {
                    console.error(error)
                    dispatch({
                      type: 'INITIALISE',
                      payload: {
                        isAuthenticated: true,
                        isAuthorized: false,
                        user: null
                        // user: {
                        //   id: response.account.homeAccountId,
                        //   avatar: null,
                        //   email: response.account.username,
                        //   name: response.account.name,
                        //   tier: 'Premium'
                        // }
                      }
                    });
                  });
              } else {
                 getAccounts();
              }
            })
            .catch(err => {
                console.log(err)
            });
      } catch (err) {
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            isAuthorized:false,
            user: null
          }
        });
      }
    };

    const isValidToken = (accessToken) => {
      if (!accessToken) {
        return false;
      }
    
      const decoded = jwtDecode(accessToken);
      const currentTime = Date.now() / 1000;
      console.log('decoded', decoded.exp - currentTime)
    
      return decoded.exp > currentTime;
    };

    
    const getAccounts = async () => {

      const currentAccounts = msalApp.getAllAccounts();

      if (currentAccounts === null) {
          console.error("No accounts detected!");
          return;
      } else if (currentAccounts.length > 1) {
          console.warn("Multiple accounts detected.");
          // Add choose account code here
      } else if (currentAccounts.length === 1) {

        silentRequest.account = msalApp.getAccountByUsername(currentAccounts[0].username);

        const accessToken = window.localStorage.getItem('accessToken');
        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken, currentAccounts[0].username);
          axios.get('UserTenant/get').then(res1 => {

            if (res1.status != 200) {
              dispatch({
                type: 'INITIALISE',
                payload: {
                  isAuthenticated: true,
                  isAuthorized: false,
                  user: null
                }
              });

            } else {
              if (res1.data.endpointRoot) {
                dispatch({
                  type: 'INITIALISE',
                  payload: {
                    isAuthenticated: true,
                    isAuthorized: (res1.status == 200),
                    user: {
                      id: currentAccounts.homeAccountId,
                      avatar: null,
                      iduser: res1.data.id,
                      email: currentAccounts[0].username,
                      name: currentAccounts[0].name,
                      endpointRoot: res1.data.endpointRoot,
                      tier: 'Premium',
                      UserPlantsCount: res1.data.UserPlantsCount,
                      PlantSubDomain: res1.data.PlantSubDomain,
                      AuditTool: res1.data.AuditTool
                    }
                  }
                });
              } else {

                axios.get('user/get').then(res => {
                  console.log('3', res)
                  dispatch({
                    type: 'INITIALISE',
                    payload: {
                      isAuthenticated: true,
                      isAuthorized: (res.status == 200),
                      user: {
                        id: currentAccounts.homeAccountId,
                        avatar: null,
                        idprofilo: res.data.idprofilo,
                        idplant: res.data.idplant,
                        idregion: res.data.plant.idRegion,
                        plantdesc: res.data.plant.descrizione,
                        language: res.data.plant.lang,
                        iduser: res.data.id,
                        email: currentAccounts[0].username,
                        name: currentAccounts[0].name,
                        endpointRoot: res1.data.endpointRoot,
                        gestioneRisorse: res.data.plant.risorse,
                        tier: 'Premium',
                        UserPlantsCount: res.data.UserPlantsCount,
                        PlantSubDomain: res.data.PlantSubDomain,
                        AuditTool: res1.data.AuditTool
                      }
                    }
                  });
                  //settings user
                  if (!window.localStorage.getItem("settings")) {
                    dataSetting.get().then((resp) => {

                      if (resp.data) {
                        console.log(resp.data);
                        saveSettings(resp.data)
                      }
                    }).catch(err => {
                      console.error(err)
                    });
                  }

                  //. user/get => error
                }).catch(err => {
                  console.error(err);
                  dispatch({
                    type: 'INITIALISE',
                    payload: {
                      isAuthenticated: true,
                      isAuthorized: false,
                      user: null
                      // user: {
                      //   id: currentAccounts.homeAccountId,
                      //   avatar: null,
                      //   email: currentAccounts[0].username,
                      //   name: currentAccounts[0].name,
                      //   defaultPlant:false,
                      //   tier: 'Premium'
                      // }
                    }
                  });
                })
              }

            }



            //. userTenant/get => error
          }).catch(function (err) {
            console.error(err);
            debugger
            dispatch({
              type: 'INITIALISE',
              payload: {
                isAuthenticated: true,
                isAuthorized: false,
                user: null
                // user: {
                //   id: currentAccounts.homeAccountId,
                //   avatar: null,
                //   email: currentAccounts[0].username,
                //   name: currentAccounts[0].name,
                //   defaultPlant:false,
                //   tier: 'Premium'
                // }
              }
            });
          });

          return;
        }
        //else if (accessToken && !isValidToken(accessToken)) {
        //  alert("Time Out Error. Please refresh the page.");
        //}
      }

      dispatch({
        type: 'INITIALISE',
        payload: {
          isAuthenticated: false,
          isAuthorized:false,
          user: null
        }
      });

    };
    
    initialise();

    const intervalId = setInterval(() => {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && !isValidToken(accessToken)) {
        alert("Time Out Error. Please refresh the page.");
        //logout();
      }
    }, 300000);

    return () => clearInterval(intervalId);

  }, []);

  function getDatafromDB(){
    return dataSetting.get()
  }

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  const msalApp = new PublicClientApplication(msalConfig);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'Azure',
        loginWithPopup,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
